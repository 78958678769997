<template>
  <router-view v-if="modulesLoaded"></router-view>
</template>

<script>
export default {
  name: 'CrudIndex',
  computed: {
    modulesLoaded() {
      return Object.keys(this.$store.state.crud.modules).length > 0
    }
  },
  mounted() {
    this.$store.commit('crud/setModule', this.$route.params.module)
    this.$store.dispatch('crud/loadModules')
  },
}
</script>
